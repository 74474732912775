import { fetchOrgList } from '@/api/accidentplan-api';
import { SortDirection } from '@/helpers/data-query';
import { FetchStates } from '@/helpers/fetch-states';
import { ref, watch } from 'vue';
import { alertError } from '@/helpers/alert-error';
import { AuthModule } from '@/store/auth';
import { SingleOrgDTO } from '@/api/dto/org';

export function useOrgList() {
  const orgs = ref<SingleOrgDTO[]>([]);
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const returnCount = ref(0);
  const total = ref(0);
  const page = ref(1);
  const pageSize = ref(100);
  const sortDir = ref<SortDirection>(SortDirection.ASC);
  const sortBy = ref<keyof SingleOrgDTO>('name');

  const fetchOrgs = async () => {
    if (!AuthModule.account) {
      orgs.value = [];
      returnCount.value = 0;
      total.value = 0;
      page.value = 1;

      return;
    }

    fetchState.value = FetchStates.FETCHING;
    try {
      const data = await fetchOrgList(
        AuthModule.account.id,
        sortBy.value ?? 'name',
        sortDir.value,
        pageSize.value,
        (page.value - 1) * pageSize.value,
      );

      orgs.value = data.results;
      returnCount.value = data.return_count;
      total.value = data.total;

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      alertError(error, 'There was an error fetching users.');
      console.error(error);
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  const onSortChanged = ({
    order,
    prop,
  }: {
    order: 'ascending' | 'descending';
    prop: keyof SingleOrgDTO;
    column: any;
  }) => {
    page.value = 1;
    sortBy.value = prop;

    if (order === 'descending') sortDir.value = SortDirection.DESC;
    else sortDir.value = SortDirection.ASC;
  };

  watch(page, fetchOrgs);
  watch(pageSize, fetchOrgs);
  watch(sortBy, fetchOrgs);
  watch(sortDir, fetchOrgs);

  fetchOrgs();

  return {
    orgs,
    fetchState,
    returnCount,
    total,
    page,
    pageSize,
    sortDir,
    sortBy,
    fetchOrgs,
    onSortChanged,
  };
}
