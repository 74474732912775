
import { defineComponent } from 'vue';
import { useOrgList } from '@/composables/useOrgList';
import { FetchStates } from '@/helpers/fetch-states';
import { useAuth } from '@/composables/useAuth';

export default defineComponent({
  name: 'UsersList',
  setup() {
    const OrgList = useOrgList();

    return {
      ...OrgList,
      FetchStates,
    };
  },
});
