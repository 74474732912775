
import { defineComponent } from 'vue';
import router from '@/router';
import OrgList from '@/components/orgs/OrgList.vue';
import { exportOrgs } from '@/api/accidentplan-api';
import { useOrgList } from '@/composables/useOrgList';

export default defineComponent({
  setup() {
    const routeToNewOrg = () =>
      router.push({ name: 'OrganizationsFormCreate' });

    return {
      routeToNewOrg,
      exportOrgs,
      ...useOrgList(),
    };
  },
  methods: {
    async exportOrgsAndDownload(orgIds: number[]) {
      const textResp = await exportOrgs(orgIds);

      // Credit to https://stackoverflow.com/a/53775165
      // for how to automatically download a response
      const blob = new Blob([textResp], { type: 'text' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'orgs.csv';
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  components: { OrgList },
});
