import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_link = _resolveComponent("el-link")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_pagination = _resolveComponent("el-pagination")
  const _component_el_container = _resolveComponent("el-container")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createBlock(_component_el_container, { direction: "vertical" }, {
    default: _withCtx(() => [
      _createVNode("h4", null, "Displaying " + _toDisplayString(_ctx.returnCount) + " out of " + _toDisplayString(_ctx.total) + " results", 1),
      _createVNode(_component_el_table, {
        stripe: "",
        data: _ctx.orgs,
        onSortChange: _ctx.onSortChanged,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "name",
            sortable: "custom",
            label: "Name"
          }),
          _createVNode(_component_el_table_column, {
            prop: "city",
            sortable: "custom",
            label: "City"
          }),
          _createVNode(_component_el_table_column, {
            prop: "state",
            sortable: "custom",
            label: "State"
          }),
          _createVNode(_component_el_table_column, {
            prop: "main_phone",
            sortable: "custom",
            label: "Main Phone"
          }),
          _createVNode(_component_el_table_column, { label: "Options" }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_el_link, {
                class: "el-button el-button--mini is-circle",
                size: "mini",
                href: `/#/organizations/edit/${row.id}`,
                icon: "el-icon-edit-outline",
                underline: false,
                circle: "",
                plain: ""
              }, null, 8, ["href"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "onSortChange"]),
      _createVNode(_component_el_pagination, {
        class: "margin-auto margin-vert",
        background: "",
        layout: "sizes, prev, pager, next",
        "page-sizes": [10, 20, 50, 100, 200],
        total: _ctx.total,
        "page-size": _ctx.pageSize,
        "onUpdate:page-size": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pageSize = $event)),
        "current-page": _ctx.page,
        "onUpdate:current-page": _cache[2] || (_cache[2] = ($event: any) => (_ctx.page = $event))
      }, null, 8, ["total", "page-size", "current-page"])
    ]),
    _: 1
  }, 512)), [
    [_directive_loading, _ctx.fetchState === _ctx.FetchStates.FETCHING]
  ])
}